/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

body {
  background: #f4f8fb;
  // background: none;
  font-family: "Hind", sans-serif;
  min-height: 100%;
  box-sizing: border-box;
}

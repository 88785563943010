/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss
$Primary: #3b5998 !default;
$Primary-80: #336fab;
$Primary-60: #6dafba;
$Primary-40: #99b7d5;
$Primary-20: #ccdbea;
$Primary-10: #e5edf4;
$OnPrimary: #fff !default;
$Secondary: #00c4b3 !default; //test color
// $Secondary:      #37474f !default;
$Secondary-80: #5f6c72;
$Secondary-60: #879195;
$Secondary-40: #afb5b9;
$Secondary-20: #d7dadc;
$Secondary-10: #ebeced;
$OnSecondary: #fff !default;
//Gray
$Gray: #37474f !default;
$Gray-80: #67758d;
$Gray-60: #879195;
$Gray-40: #95989a; //DT update
$Gray-30: #eaeff0; //DT Update
$Gray-20: #f7f7f7; // DT update
$Gray-10: #dedfe1; //update
$LightBlue: #21a0d2 !default;
$OnLightBlue: #fff !default;
$InProgress: #f26833 !default;
$OnInProgress: #37474f !default;
$Background: #f4f8fb !default;
$OnBackground: #270b1a !default;
$Surface: #fff !default;
$OnSurface: #290a1d !default;
$Error: #c50022 !default;
$OnError: #fff !default;
$Warning: #ff8f00 !default;
$OnWarning: #fff !default;
$Info: #21a0d2 !default;
$OnInfo: #fff !default;
$Success: #65ac1e !default;
$OnSuccess: #fff !default;
//
// Color system
//
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$sushi: #7CB342;
$denim: #1565C0;
$brown: #B3573E;
$disco: #841B53;
$loulou: #330A26;
$dove-gray: #727272;
$wild-sand: #f6f6f6;
$catskill-white: #F5F8FA;
$rose-Bud-Cherry: #880E4F;
$web-orange: #F1A801;
$periwinkle-gray: #B6CCE3;
$periwinkle-gray-dark: #CACAE3;
$burning-orange: #FF7043;
$Punch: #DF4229;
$SanFelix: #0C5606;
$web-orange-o5: rgba(241, 168, 1, 0.05);
$Sushi-o5: rgba(124, 179, 66, 0.05);
$burning-orange-o5: rgba(255, 112, 67, 0.05);
$denim-o5: rgba(21, 101, 192, 0.05);
$tamarind: #270D20;
$CoffeeBean: #280B1E;
$venus: #978691;
$aubergine: #2E0920;
$theme-colors: ( primary: $blue, secondary: $gray-600, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800);
// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.

/* bootstrap variable overwrite  */

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 16px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge( ( 0: 0, 1: ( $spacer * 0.25), //4px
2: ( $spacer * 0.5), //8px
3: ( $spacer * 0.75), //12px
4: $spacer, //16px
5: ( $spacer * 1.5), //24px
6: ( $spacer * 2.25), //36px
7: ( $spacer * 3) //48px
), $spacers);
$font-robotoregular: "robotoregular";
$font-robotomedium: "robotomedium";
$font-robotolight: "robotolight";
$font-robotobold: "robotobold";
$font-robotoblack: "robotoblack";
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

@import "theme/theme";
@import "theme/theme-variables";
// 3rd party libraries
@import "../node_modules/@progress/kendo-theme-default/scss/all";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@font-face {
    font-family: "icomoon";
    src: url("../src/assets/fonts/icomoon.eot?6od4kq");
    src: url("../src/assets/fonts/icomoon.eot?6od4kq#iefix") format("embedded-opentype"),
        url("../src/assets/fonts/icomoon.ttf?6od4kq") format("truetype"),
        url("../src/assets/fonts/icomoon.woff?6od4kq") format("woff"),
        url("../src/assets/fonts/icomoon.svg?6od4kq#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// html,
// body {
//     height: 100%;
// }
html {
    min-height: 100% !important;
}

.body {
    min-height: 100%;
    font-family: Hind;
    background: #f4f8fb;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: #880e4f;
}

// placeholder
.k-input::-ms-clear {
    display: none
}

.k-input::-moz-placeholder {
    color: hsla(0,0%,39.6%,.5);
    opacity: 1;
    -moz-user-select: none;
    user-select: none
}

.k-input:-ms-input-placeholder {
    opacity: 1;
    -ms-user-select: none;
    user-select: none
}

.k-input::placeholder {
    color: hsla(0,0%,39.6%,.5);
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.k-input:-ms-input-placeholder {
    color: hsla(0,0%,39.6%,.5)
}

.k-input:invalid {
    box-shadow: none
}

.k-textbox::-ms-clear {
    display: none
}

.k-textbox::-moz-placeholder {
    color: hsla(0,0%,39.6%,.5);
    opacity: 1;
    -moz-user-select: none;
    user-select: none
}

.k-textbox:-ms-input-placeholder {
    opacity: 1;
    -ms-user-select: none;
    user-select: none
}

.k-textbox::placeholder {
    color: hsla(0,0%,39.6%,.5);
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
// 

.btn-primary {
    color: $white;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.formHolder {
    padding: 24px;
}

.higlightSection {
    background: $Gray-30;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-bottom: 16px;
    position: relative;
}

.SectionWrapper {
    padding: 16px 24px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 50px;
}

.borderBtm {
    border-bottom: 1px solid $Gray-30;
}

/*------------------------------------------*/

/*	  Kendo UI updated.
/*------------------------------------------*/

.k-checkbox:checked,
.k-checkbox:checked:focus {
    border-color: $rose-Bud-Cherry;
    background-color: $rose-Bud-Cherry;
}

.k-autocomplete .k-clear-value {
    height: auto !important;
}

// -----------------------------------------
// -----------------------------------------
.align-content {
    .k-autocomplete {
        height: 31px;
        &:active,
        &:focus {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        // demo
        &.ng-invalid.ng-touched {
            border-color: rgba(0, 0, 0, 0.08);
        }
        &.k-state-focused {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
    & .k-widget.form-control .k-input {
        color: $OnSurface;
        padding-left: 0.5rem;
        height: inherit !important;
    }
}

#payment-form {
    .controlHolder {
        input:focus,
        input:active {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

// -------------------------------------------------------
.bg-code {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: $Gray-30;
}

.code-container {
    display: grid;
    grid-column-gap: 20px;
    align-items: center;
    grid-template-columns: 12% auto;
}

.code-container-application {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 12% auto;
}

.code-icon {
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    border-right: 1px solid rgba(41, 10, 29, 0.1);

    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.img-email img {
    width: 18px;
}

.img-phone img {
    width: 16px;
}
.code-fields{
    padding-top: 1rem;
    & input {
        width: 28px !important;
        min-height: 32px;
        text-align: center !important;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid rgba(41, 10, 29, 0.5);
    }
    & input:not(:first-of-type) {
        margin-left: 10px;
    }
    & input.form-control {
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.08) !important;
        border-radius: 1.5px;
        background-color: $white;
        color: #3a092b;
        padding: 1px 7px;
        font-size: 14px;
        box-shadow: none;
    }
}

.field-error input.form-control {
    border-color: rgba(255, 112, 67, 0.8) !important;
}

.btn-theme-primary {
    line-height: normal;
    display: inline-block;
    background: $rose-Bud-Cherry;
    border: 1px solid $rose-Bud-Cherry;
    padding: 3px 6px;
    font-size: small;
    color: rgba(255, 255, 255, 0.88);
}

.btn-theme-primary-display {
    margin-left: 20px;
    border-radius: 0;
    margin-bottom: 2px;
}

.btn-theme-primary:hover {
    background: $rose-Bud-Cherry;
    color: rgba(255, 255, 255, 0.88);
}

.btn-theme-primary:active,
.btn-theme-primary:focus {
    outline: 0;
    box-shadow: none;
}

.text-resend,
.text-match {
    color: $rose-Bud-Cherry;
}

.img-deny {
    width: 30px;
}

.img-check {
    width: 20px;
}

.code-fields .pincode-input-text,
.form-control.pincode-input-text {
    flex-grow: unset;
}

.code-fields .pincode-input-container {
    width: auto;
}

.code-fields .timerEmail:hover {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.formHolder .controlHolder label {
    font-size: 14px;
    line-height: 17px;
    display: block;
    margin: 0 0 0;
    font-weight: bold;
    color: $OnSurface;
    font-family: Hind;
}

.formHolder .controlHolder {
    overflow: hidden;
    padding: 0 0 26px;
    position: relative;
    & .k-input,
    & .k-button-solid-base {
      border: 1px solid $Gray-30;
      background-color: $catskill-white;
      &:focus,&:focus-within {
        box-shadow: none;
      }
    }
}

.formHolder .controlHolder .k-textbox-container {
    width: 100%;
    padding-top: 0;
}

.borderB {
    border-bottom: 1px solid $Gray-30;
}

.k-notification-success {
    color: $white;
    border-color: $sushi;
    background-color: $sushi;
    .k-notification-wrap {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .k-i-success {
            font-size: 22px;
        }
    }
}

.toast-notification {
    padding: 15px;
}

.toast-notification {
    min-width: 536px;
    padding: 6%;
    top: 32px;
}

// toqeer style
.k-tabstrip .k-item .k-link {
    display: inline-block;
    font-weight: bold;
    padding: 16px 0px 10px 0px;
    margin-left: 20px;
    margin-right: 10px;
    color: #656565;
    font-size: 14px;
    text-decoration: none;
}

// .k-tabstrip-top > .k-tabstrip-items{
//     border:none;
// }
.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
    border: none;
    border-bottom: 1px solid #ebebeb;
}

.brokerPortal .k-tabstrip ul {
    padding-left: 4px;
}

.k-tabstrip .k-item.k-state-active .k-link {
    border-bottom: 5px solid $rose-Bud-Cherry;
    color: $OnSurface;
    padding-bottom: 5px;
}

.k-tabstrip > .k-content.k-state-active {
    padding: 0;
}

.k-tabstrip-top > .k-tabstrip-items {
    border-bottom: 1px solid $Gray-30;
}

.brokerPortal .k-progress-status {
    display: none;
}

.brokerPortal .leasing-progressbar .k-progressbar .k-selected {
    background-color: #7cb342;
    border-color: #7cb342;
}
.brokerPortal .k-progressbar .k-selected {
    background-color: #f1a801;
    border-color: #f1a801;
}
.brokerPortal .progress-sushi .k-progressbar .k-selected {
    background-color: #7cb342;
    border-color: #7cb342;
}
.brokerPortal .progress-bruning-orange .k-progressbar .k-selected {
    background-color: #ff7043;
    border-color: #ff7043;
}
.brokerPortal .k-progressbar > .k-progress-status-wrap {
    background-color: $white;
}

.k-floating-label-container{
    width: 100%;
    padding-top: initial;
}
.brokerSignUp .k-maskedtextbox > .k-textbox,
.brokerSignUp .k-textbox-container > .k-textbox,
.k-textbox-container > .k-widget,
.k-floating-label-container > .k-textbox,
.k-floating-label-container > .k-widget {
    height: 34px !important;
}

.brokerSignUp .k-searchbar {
    padding-top: 2px;
}

.brokerSignUp .k-textbox {
    color: $OnSurface;
    font-family: Hind;
    font-size: 14px;
    line-height: 20px;
}

.brokerSignUp .k-input {
    color: $OnSurface;
    font-family: Hind;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}

#autoComplete .k-input {
    padding-top: 0 !important;
}

// toqeer style end

/*********************************************
    mdsr: overwrite kendo -g
**********************************************/
.k-window {
    box-shadow: none;
}
.k-window-content {
    padding: 0 !important;
}

.k-combobox .k-dropdown-wrap {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    height: 30px;
    border-color: $Gray-30;
    background-color: $catskill-white;
    .k-input {
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.k-combobox .k-select {
    border-color: $Gray-30;
    background-color: $catskill-white;
}

.k-combobox > :hover .k-select {
    border-color: $Gray-30;
    background-color: $catskill-white;
}

// --- dropdown
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
    background-color: $rose-Bud-Cherry;
    &:hover {
        background-color: $rose-Bud-Cherry;
    }
}

// ----------------------------------------
.btnArea .btn-primary,
.btnArea .btn-secondary {
    color: $OnSurface;
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    background: $white;
    border: 1px solid $OnSurface;
    padding: 0 15px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

.btnArea .btn-primary {
    border: 1px solid $rose-Bud-Cherry;
    background: $rose-Bud-Cherry;
    color: $white;
    &:active,
    &:focus {
        outline: none;
        color: $white;
        border-color: $rose-Bud-Cherry !important;
        background-color: $rose-Bud-Cherry !important;
        box-shadow: none !important;
    }
}

.btnArea .btn-secondary {
    &:active,
    &:focus {
        outline: none;
        color: $OnSurface !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }
}

.fa-ellipsis-v {
    -webkit-text-stroke: 1px white;
}

// ----------------- MDSR --------------------

/*-------------------------------------------*/

/*--------------- color's -g -----------------*/

.bg-sushi {
    background-color: $sushi;
}

.bg-webOrange {
    background-color: $web-orange;
}

.bg-periwinkle-gray {
    background-color: $periwinkle-gray;
}

.bg-periwinkle-gray-dark {
    background-color: $periwinkle-gray-dark;
}

.bg-burning-orange {
    background-color: $burning-orange;
}

.bg-sush-05 {
    background-color: $Sushi-o5;
}

.bg-webOrange-05 {
    background-color: $web-orange-o5;
}

.bg-burning-orange-05 {
    background-color: $burning-orange-o5;
}

.bg-denim-05 {
    background-color: $denim-o5;
}

.bg-punch {
    background-color: $Punch;
}

.bg-SanFelix {
    background-color: $SanFelix;
}
.leasing-progress-sushi {
    .k-progressbar {
        .k-selected {
            background-color: $sushi;
            border-color: $sushi;
        }
    }
}
.leasing-progress-burning-orange {
    .k-progressbar {
        .k-selected {
            background-color: #ff7043;
            border-color: #ff7043;
        }
    }
}

.leasing-progress-yellow {
    .k-progressbar {
        .k-selected {
            background-color: #f1a801;
            border-color: #f1a801;
        }
    }
}
// ----------------ellipsis--------------------
.rt-theme-color {
    color: $rose-Bud-Cherry;
}

/*	  Tenant Verification : login -g
/*------------------------------------------*/

.tenant-verificationForm {
    .input-field {
        width: 100%;
        input {
            width: 100%;
            font-size: 14px;
            color: $OnSurface;
            border: 1px solid rgba(0, 0, 0, 0.08);
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -o-border-radius: 3px;
            outline: none;
            height: 30px;
            padding: 3px 8px;
            transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
        }
        &:active,
        &:focus {
            outline: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        &::placeholder {
            color: $OnSurface;
        }
        &:-ms-input-placeholder {
            color: $OnSurface;
        }
        &::-ms-input-placeholder {
            color: $OnSurface;
        }
    }
    .k-textbox {
        border: 0;
        height: auto;
    }
}

/*------------------------------------------*/

/*	  Broker Lister  -g
/*------------------------------------------*/

.screening-lister {
    .k-grid-content {
        overflow: auto;
    }
    .gridArea .k-grid {
        tr.k-alt {
            background-color: transparent;
        }
        tbody tr:hover {
            background-color: transparent;
        }
        td {
            padding: 0px !important;
            border-bottom: none !important;
        }
        tr:last-child td {
            padding-bottom: 15px !important;
        }
        .k-grid-norecords {
            td {
                padding-top: 15px !important;
            }
        }
    }
}

/*------------------------------------------*/

/*	  Module popup  -g
/*------------------------------------------*/

app-grid-context-menu,
app-multi-select-menu,
app-context-menu {
    .k-popup {
        z-index: inherit 2;
        background-color: $white;
        border: solid 1px #cdcdcd;
        box-sizing: inherit;
    }
    .k-animation-container-shown,
    .k-animation-container > .k-popup {
        box-shadow: none;
    }
    .pointer-up {
        z-index: inherit 1;
        margin: -13px 10px 0px 0px;
        text-align: right;
    }
    .pointer-down {
        margin: 0px 10px -14px 0px;
        text-align: right;
    }
}

app-multi-select-menu {
    .k-popup {
        margin-top: 15px;
        border: none;
        div.wrapper {
            border: solid 1px #cdcdcd;
            background: white;
        }
    }
}

/*------------------------------------------*/

/*	  Confirmation model popup
/*------------------------------------------*/

.confirm-dialog {
    &-box {
        padding: 24px;
    }
    .icon-info {
        padding-bottom: 24px;
        text-align: center;
        display: inherit;
        img {
            width: 30px;
        }
    }
    .text-header {
        font-size: 18px;
        color: $OnSurface;
        padding-bottom: 5px;
    }
    .text-msg {
        font-size: 14px;
        color: $OnSurface;
    }
    .btnArea {
        padding: 24px;
        overflow: hidden;
    }
    .btnArea .leftArea {
        float: left;
    }
    .btnArea .RightArea {
        float: right;
    }
}

.input-phone .iti__flag-container,
.input-phone .iti__country-list {
    width: 100%;
}

.input-phone .iti__selected-flag {
    display: inline-flex;
}

.input-phone #phone,
.input-phone .iti,
.input-phone .input-phone-field,
.input-phone .iti.iti--allow-dropdown,
.input-phone .dropdown-menu.country-dropdown {
    width: 100%;
}

.input-phone .k-textbox-container {
    width: 100%;
    padding-top: 0;
}

.input-phone #phone {
    height: 30px;
    border: 1px solid $Gray-30;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: $OnSurface;
}

.input-phone #phone:focus-visible {
    outline: none;
    box-shadow: none;
}

// Add Lead

.input-phone {
    & label {
        color: $OnSurface;
        font-size: 14px;
        line-height: 19px;
        display: block;
        margin: 0 0 0;
        font-weight: bold;
        color: $OnSurface;
        font-family: Hind;
    }

    & #cellphone_1 {
        height: 30px;
        border-radius: 3px;
        border: 1px solid $Gray-30;
        background-color: $catskill-white;

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: $Gray-30;
        }
    }

}

.input-focus {
    border-color: $rose-Bud-Cherry !important;
}

.k-input-inner {
    font-size: 1rem;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.k-list {
    background-color: #f6f6f6;
    &-group-item:first-child {
        display: none;
    }
}
.k-popup .k-list-group-sticky-header {
    border-bottom-color: $gray-300;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.06);
}

/*------------------------------------------*/

/*	  Override | Kendo
/*------------------------------------------*/

.k-rounded-md {
    border-radius: 3px !important;
}
.k-picker-solid {
    border-color: $Gray-30;
    background-image: none;
    background-color: $catskill-white;
    &:hover,&:focus{
        background-color: $catskill-white;
    }
}